import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerItems: [
        {
          title: "Information",
          links: [
            { linkTitle: "Home", link: "/" },
            { linkTitle: "Jobs", link: "/jobs" },
          ],
        },
        {
          title: "Support",
          links: [
            { linkTitle: "FAQ", link: "/faq" },
            { linkTitle: "Download", link: "/download" },
            { linkTitle: "Privacy Policy", link: "/privacy" },
            { linkTitle: "Terms & Condition", link: "/terms" },
          ],
        },
      ],
      isSwitchToggle: false,
    };
    this.toggleSwitcher = this.toggleSwitcher.bind(this);
    this.setTheme = this.setTheme.bind(this);
  }

  setTheme = (color) => {
    document.getElementById("colorTheme").href =
      "assets/colors/" + color + ".css";
  };

  toggleThem = () => {
    if (document.body.getAttribute("data-bs-theme") === "light") {
      document.body.setAttribute("data-bs-theme", "dark");
    } else {
      document.body.setAttribute("data-bs-theme", "light");
    }
  };

  toggleSwitcher = () => {
    this.setState({ isSwitchToggle: !this.state.isSwitchToggle });
  };
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <Container>
            <Row>
              <Col lg="3" className="mt-4">
                <img
                  src="/assets/images/inverlux-logo-white.png" // path to the logo
                  alt="INVERLUX"
                  style={{ height: "60px", marginRight: "8px" }} // adjust height as needed
                />
                <div className="text-muted mt-4">
                  <p>"Empowering Luxurious, Reliable Energy Wherever You Go"</p>
                  <p>
                    Delivering advanced pure sine wave inverter with a touch of
                    elegance, enabling seamless and dependable AC power access,
                    anytime, anywhere.
                  </p>
                </div>
              </Col>
              <Col lg="3" className="mt-4"></Col>
              {this.state.footerItems.map((item, key) => (
                <Col lg="3" className="mt-4" key={key}>
                  {item.icon ? (
                    <Link className="footer-logo text-uppercase" href="#">
                      <i className={item.icon}></i>
                      <span>{item.title}</span>
                    </Link>
                  ) : (
                    <h4>{item.title}</h4>
                  )}
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {item.links.map((link, key) => (
                        <li key={key}>
                          <Link to={link.link}>{link.linkTitle}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </footer>

        <FooterLinks />

        <div
          id="style-switcher"
          className={this.state.isSwitchToggle ? "show" : " "}
          style={{ left: this.state.isSwitchToggle ? "0px" : "-189px" }}
        >
          <div>
            <h3>Select your color</h3>
            <ul className="pattern">
              <li>
                <Link
                  className="color1"
                  onClick={() => this.setTheme("cyan")}
                  to="#"
                ></Link>
              </li>
              <li>
                <Link
                  className="color2"
                  onClick={() => this.setTheme("red")}
                  to="#"
                ></Link>
              </li>
              <li>
                <Link
                  className="color3"
                  onClick={() => this.setTheme("green")}
                  to="#"
                ></Link>
              </li>
              <li>
                <Link
                  className="color4"
                  onClick={() => this.setTheme("pink")}
                  to="#"
                ></Link>
              </li>
              <li>
                <Link
                  className="color5"
                  onClick={() => this.setTheme("blue")}
                  to="#"
                ></Link>
              </li>
              <li>
                <Link
                  className="color6"
                  onClick={() => this.setTheme("purple")}
                  to="#"
                ></Link>
              </li>
              <li>
                <Link
                  className="color7"
                  onClick={() => this.setTheme("orange")}
                  to="#"
                ></Link>
              </li>
              <li>
                <Link
                  className="color8"
                  onClick={() => this.setTheme("yellow")}
                  to="#"
                ></Link>
              </li>
            </ul>
          </div>
          <div className="bottom">
            {/* <Link
              to="#"
              id="mode"
              className="mode-btn text-white"
              onClick={this.toggleThem}
            >
              <i className="mdi mdi-weather-sunny bx-spin mode-light"></i>
              <i className="mdi mdi-moon-waning-crescent mode-dark"></i>
            </Link> */}
            {/* <Link
              to="#"
              onClick={this.toggleSwitcher}
              className="settings rounded-right"
            >
              <i className="mdi mdi-cog  mdi-spin"></i>
            </Link> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
