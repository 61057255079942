import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    productStrengths: [
      {
        title: "Power Inverter",
        icon: "pe-7s-gleam",
        description:
          "Our pure sine wave inverters enable the use of AC-powered devices on the go—in locations without power outlets like campsites, vehicles (RV, campervan, etc.), or job sites. They’re also suitable for business and industrial uses, such as coffee carts, food trucks, ambulances, UPS systems, and off-grid solar setups.",
      },
      {
        title: "Advanced Customization Settings",
        icon: "pe-7s-config",
        description:
          "Our power inverters offer advanced settings, allowing users to customize and fine-tune performance to meet specific needs. Whether adjusting voltage, frequency, or power modes, our inverters provide flexibility for optimal use in a variety of applications.",
      },
      {
        title: "Energy Saving",
        icon: "pe-7s-leaf",
        description:
          "All of our inverters come equipped with an energy-saving mode, designed to reduce power consumption when demand is low. This feature not only saves energy but also enhances the lifespan of the inverter and connected devices, making our products both eco-friendly and cost-effective.",
      },
      {
        title: "Smartphone App",
        icon: "pe-7s-phone",
        description:
          "Control your inverter conveniently with our user-friendly smartphone app. Monitor performance, switch modes, and make real-time adjustments from your device, ensuring you have full control of your power source at your fingertips.",
      },
      {
        title: "Comprehensive Safety Protections",
        icon: "pe-7s-check",
        description:
          "Safety is paramount in our designs. Our inverters come with complete protection features, including over-voltage, under-voltage, overload, short-circuit, and thermal protections. With our inverters, you can operate with confidence, knowing that your equipment and appliances are secure.",
      },
      {
        title: "Exceptional Durability",
        icon: "pe-7s-clock",
        description:
          "Built to last, our inverters are designed to withstand rigorous conditions and outlast competitors. We use premium components and advanced engineering to ensure longevity, providing reliable power even in the toughest environments. Our products stand out for their durability compared to lower-quality alternatives.",
      },
    ],
    companyStrengths: [
      {
        title: "Trusted Worldwide",
        icon: "pe-7s-global",
        description:
          "We are a global company, trusted by customers across the United States and beyond. Our commitment to quality and reliability has earned us a reputation as a dependable partner in power solutions.",
      },
      {
        title: "Customer-Centric Design",
        icon: "pe-7s-user",
        description:
          "Every product we create is designed with the user in mind. We listen to our customers’ needs and incorporate their feedback, ensuring our inverters are intuitive, functional, and built to exceed expectations.",
      },
      {
        title: "Dedicated Customer Support",
        icon: "pe-7s-chat",
        description:
          "We believe in strong communication with our customers. Our team is always ready to assist through our online chat support, providing prompt responses to inquiries and technical assistance to keep you powered up, whenever you need it.",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="companyStrengths">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="INVERLUX LLC"
              description="Delivering advanced inverter technology with a touch of elegance, enabling seamless and dependable AC power access, anytime, anywhere."
            />

            <Row className="mt-5">
              {/* render service box */}
              {this.state.companyStrengths.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
        <section className="section bg-light" id="productStrengths">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Strengths"
              description="Inverlux combines advanced inverter technology with a commitment to quality, customization, and reliability. Our inverters offer unique energy-saving features, seamless smartphone control, and complete safety protections. Built to last, they provide dependable performance far beyond standard alternatives."
            />

            <Row className="mt-5">
              {/* render service box */}
              {this.state.productStrengths.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
