import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"; // Ensure you have reactstrap installed

class BlogBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  render() {
    return (
      <React.Fragment>
        <Col lg="4">
          <div className="blog-box mt-4">
            <div
              onClick={this.toggleModal}
              style={{
                cursor: "pointer",
                width: "100%",
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={this.props.blog.image}
                alt=""
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </div>
            <div>
              <h5 className="mt-4 text-muted">{this.props.blog.topic}</h5>
              <h4 className="mt-3">
                <Link to={this.props.blog.link} className="blog-title">
                  {" "}
                  {this.props.blog.title}{" "}
                </Link>
              </h4>
              <p className="text-muted">{this.props.blog.description}</p>
              <div className="mt-3">
                <Link to={this.props.blog.link} className="read-btn">
                  Read More <i className="mdi mdi-arrow-right"></i>
                </Link>
              </div>
            </div>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggleModal}
              size="lg"
            >
              <ModalHeader toggle={this.toggleModal}>
                {this.props.blog.title}
              </ModalHeader>
              <ModalBody
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "300px" }}
              >
                <img
                  src={this.props.blog.image}
                  alt={`${this.props.blog.title} larger view`}
                  className="img-fluid"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "70vh", // Set max height to 90% of the viewport height
                    height: "auto", // Maintain aspect ratio
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggleModal}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default BlogBox;
