import React, { Component } from "react";
// import Section from "./section-download";
import Footer from "../../components/Footer/footer";
import { Container, Row } from "reactstrap";
//Import Section Title
import SectionTitle from "../../components/common/section-title";

import NavbarPage from "../../components/Navbar/NavbarPage";

//Import Home Button
import AccountHomeButton from "../Auth/account-home-button";
import FAQBox from "../../components/Testimonials/faq-box";

class Index9 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navClass: "navbar-white",
      testimonials: [
        {
          id: 1,
          // image: "assets/images/testimonials/user-1.jpg",
          name: "What is an inverter charger?",
          cmpName: "Charleston",
          message:
            "“An inverter charger is a device that combines both an inverter and a battery charger into one unit. It can convert DC power from batteries into AC power to run household appliances (inverter function) and recharge batteries when connected to an AC power source (charger function).”",
        },
        {
          id: 2,
          // image: "assets/images/testimonials/user-2.jpg",
          name: "What is the difference between pure sine wave and modified sine wave inverter chargers?",
          cmpName: "Worcester",
          message:
            "“A pure sine wave inverter charger produces a smooth, consistent wave, ideal for sensitive electronics and appliances. Modified sine wave inverters produce a more square-like wave, which may not be suitable for certain sensitive devices and may cause issues with motors or specific electronics.”",
        },
        {
          id: 3,
          // image: "assets/images/testimonials/user-3.jpg",
          name: "Can I use the inverter charger with solar panels?",
          cmpName: "Lynchburg",
          message:
            "“Yes, you can absolutely use an Inverlux inverter charger with solar panels to create an efficient, sustainable power system. By adding a solar charge controller, you can enhance your setup to seamlessly manage energy from solar panels and keep your battery optimally charged. This flexibility allows you to customize your power solution based on your unique energy needs, maximizing the benefits of both the inverter charger and solar energy.”",
        },
      ],
    };
  }

  componentDidMount() {
    document.getElementById("colorTheme").href = "assets/colors/blue.css";
  }

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage navClass={this.state.navClass} />

        {/* render home button */}
        <AccountHomeButton />
        <section className="section bg-light home-half">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Frequently Asked Questions"
              description="Our FAQ page provides quick answers to the most common questions about our products, services, policies, and processes. It’s designed to help you find information easily and get the support you need."
            />
            <Row className="mt-5">
              {/* render testimonials box */}
              {this.state.testimonials.map((testimonial, key) => (
                <FAQBox key={key} testimonial={testimonial} />
              ))}
            </Row>
          </Container>
        </section>

        {/* Importing Get Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index9;
