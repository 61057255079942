import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Blog Box
import BlogBox from "./blog-box";

class Blog extends Component {
  state = {
    blogs: [
      {
        id: 1,
        image: "assets/images/blog/img-1.jpg",
        topic: "Pure Sine Wave Inverter Charger",
        title: "Inverter Charger for RV Integration",
        description:
          "With bi-directional inverting DC to AC power and AC charging to DC battery, the user can set up the PSW-1500 with a backup battery and serve as an UPS system for mission-critical applications. For RV, caravan, campervan, and motorhome users, this is also the perfect fit to charge the auxiliary battery when shore power is available and invert DC to AC power when on mobile...",
        link: "",
      },
      {
        id: 2,
        image: "assets/images/blog/img-2.jpg",
        topic: "Automatic Transfer Switch",
        title: "Outdoor Durability",
        description:
          "The Rugged Design of the ATS-16A with IP67 ABS Housing. Built to withstand challenging outdoor environments, the ATS-16A Automatic Transfer Switch features IP67-rated ABS housing, providing excellent protection against dust and water...",
        link: "",
      },
      {
        id: 3,
        image: "assets/images/blog/img-3.jpg",
        topic: "Company",
        title: "About Inverlux",
        description:
          "Inverlux is a USA-based company dedicated to designing premium power products, all crafted in Taiwan for unmatched quality. Our product line, from advanced inverters to rugged power solutions, embodies the high standards of American engineering combined with the precision and expertise of Taiwanese manufacturing. Trusted worldwide, Inverlux products deliver both innovation and reliability...",
        link: "",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light active" id="news">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Latest News"
              description="Stay updated with our latest announcements, product releases, industry insights, and company updates. This section brings you the newest developments from Inverlux, keeping you informed on our journey, innovations, and continued commitment to delivering advanced power solutions worldwide."
            />

            <Row className="mt-4">
              {/* Render blog boxes */}
              {this.state.blogs.map((blog, key) => (
                <BlogBox key={key} blog={blog} />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Blog;
