import React, { Component } from "react";
// import { Link } from "react-router-dom";

import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button,
} from "reactstrap";
import ScrollspyNav from "./scrollSpy";

//Import Stickey Header
import StickyHeader from "react-sticky-header";
import "../../../node_modules/react-sticky-header/styles.css";
//Import Home Button
import AccountHomeButton from "../../pages/Auth/account-home-button";

class Navbar_Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Home" },
        // { id: 2, idnm: "features", navheading: "Features" },
        { id: 4, idnm: "products", navheading: "Products" },
        { id: 3, idnm: "companyStrengths", navheading: "About" },
        // { id: 3, idnm: "about", navheading: "About" },
        { id: 5, idnm: "news", navheading: "News" },
        { id: 6, idnm: "contact", navheading: "Contact" },
      ],
      isOpenMenu: false,
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    const isIndexPage = window.location.pathname === "/";

    function windowScroll() {
      const navbar = document.getElementById("navbar");
      const scrollTopButton = document.getElementById("scrollTopButton");

      if (navbar) {
        if (
          document.body.scrollTop >= 50 ||
          document.documentElement.scrollTop >= 50
        ) {
          navbar.classList.add("nav-sticky");
        } else {
          navbar.classList.remove("nav-sticky");
        }
      }
      // Show scroll-to-top button when scrolled down 100px
      if (scrollTopButton) {
        if (
          document.body.scrollTop >= 100 ||
          document.documentElement.scrollTop >= 100
        ) {
          scrollTopButton.style.display = "block";
        } else {
          scrollTopButton.style.display = "none";
        }
      }
    }

    window.addEventListener("scroll", (ev) => {
      ev.preventDefault();
      windowScroll();
    });

    // Scroll to the top function
    function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let TargetId = this.state.navItems.map((item) => {
      return item.idnm;
    });

    return (
      <React.Fragment>
        {/* Scroll to Top Button */}
        <button
          id="scrollTopButton"
          onClick={scrollToTop}
          style={{
            display: "none", // Initially hidden
            position: "fixed",
            bottom: "30px",
            right: "30px",
            backgroundColor: "#3FA7D6",
            color: "white",
            border: "none",
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            textAlign: "center",
            cursor: "pointer",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
            zIndex: 1000, // Ensures it's on top
          }}
        >
          <i className="mdi mdi-arrow-up"></i>
        </button>
        <StickyHeader
          header={
            <div
              className={
                this.props.navClass +
                " navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
              }
              id="navbar"
            >
              <Container>
                <NavbarBrand className="logo text-uppercase">
                  <img
                    src="/assets/images/inverlux-logo.png"
                    alt="INVERLUX"
                    style={{ height: "40px", marginRight: "8px" }}
                  />
                </NavbarBrand>

                {isIndexPage ? (
                  <>
                    <NavbarToggler
                      className=""
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      onClick={this.toggle}
                    >
                      <i className="mdi mdi-menu"></i>
                    </NavbarToggler>

                    <Collapse
                      id="navbarCollapse"
                      isOpen={this.state.isOpenMenu}
                      navbar
                    >
                      <ScrollspyNav
                        scrollTargetIds={TargetId}
                        activeNavClass="active"
                        scrollDuration="100"
                        headerBackground="true"
                      >
                        <Nav
                          className="navbar-nav navbar-center"
                          id="mySidenav"
                        >
                          {this.state.navItems.map((item, key) => (
                            <NavItem
                              key={key}
                              className={
                                item.navheading === "Home" ? "active" : ""
                              }
                            >
                              <NavLink href={`#${item.idnm}`}>
                                {item.navheading}
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                      </ScrollspyNav>
                      <div className="nav-button ms-auto">
                        <Nav className="navbar-right nav" navbar>
                          <NavItem>
                            <Button
                              type="button"
                              color="primary"
                              className=" navbar-btn btn-rounded waves-effect waves-light"
                              onClick={() =>
                                window.open(
                                  "https://www.amazon.com/s?k=INVERLUX",
                                  "_blank"
                                )
                              }
                            >
                              Shop Now
                            </Button>
                          </NavItem>
                        </Nav>
                      </div>
                    </Collapse>
                  </>
                ) : (
                  <AccountHomeButton />
                )}
              </Container>
            </div>
          }
          stickyOffset={-100}
        ></StickyHeader>
      </React.Fragment>
    );
  }
}

export default Navbar_Page;
