import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"; // Ensure you have reactstrap installed

class PricingBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Col lg="4">
          <div className="text-center pricing-box ">
            {/* Product Image */}
            <div onClick={this.toggleModal} style={{ cursor: "pointer" }}>
              <img
                src={this.props.pricing.image}
                alt={`${this.props.pricing.title} product`}
                className="img-fluid mb-3"
                style={{ maxHeight: "150px", objectFit: "contain" }}
              />
            </div>
            {this.props.pricing.isPopular ? (
              <div className="ribbon-box">
                <span>Popular</span>
              </div>
            ) : null}
            {this.props.pricing.isNew ? (
              <div className="ribbon-box">
                <span>New</span>
              </div>
            ) : null}
            <h4 className="text-uppercase">{this.props.pricing.title}</h4>
            <h1>${this.props.pricing.price}</h1>
            <h6 className="text-uppercase text-muted">
              {this.props.pricing.model}
            </h6>
            <div className="plan-features mt-5">
              {this.props.pricing.type === "inverter" ? (
                <>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Input:</span>
                    <b className="text-primary">
                      {this.props.pricing.specifications.input}
                    </b>
                  </p>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Output*:</span>
                    <b className="text-primary">
                      {this.props.pricing.specifications.output}
                    </b>
                  </p>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>USB:</span>
                    <b className="text-primary">
                      {this.props.pricing.specifications.usb}
                    </b>
                  </p>
                </>
              ) : null}
              {this.props.pricing.type === "ats" ? (
                <>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Input Main:</span>
                    <b className="text-primary">
                      {this.props.pricing.specifications.inputMain}
                    </b>
                  </p>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Input Sub:</span>
                    <b className="text-primary">
                      {this.props.pricing.specifications.inputSub}
                    </b>
                  </p>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Output:</span>
                    <b className="text-primary">
                      {this.props.pricing.specifications.output}
                    </b>
                  </p>
                </>
              ) : null}
            </div>
            <div className="plan-features mt-5">
              {this.props.pricing.type === "inverter" ? (
                <>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Inverter Charger (UPS):</span>
                    <b className="text-primary">
                      {this.props.pricing.features.inverterCharger}
                    </b>
                  </p>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>AC bypass:</span>
                    <b className="text-primary">
                      {this.props.pricing.features.bypass}
                    </b>
                  </p>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Charging:</span>
                    <b className="text-primary">
                      {this.props.pricing.features.charging}
                    </b>
                  </p>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Smartphone Control:</span>
                    <b className="text-primary">
                      {this.props.pricing.features.smartphoneControl}
                    </b>
                  </p>
                </>
              ) : null}
              {this.props.pricing.type === "ats" ? (
                <>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Housing:</span>
                    <b className="text-primary">
                      {this.props.pricing.features.housing}
                    </b>
                  </p>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Fast Switching Time:</span>
                    <b className="text-primary">
                      {this.props.pricing.features.fastSwitchingTime}
                    </b>
                  </p>
                  <p
                    style={{ marginBottom: "5px" }}
                    className="d-flex justify-content-between"
                  >
                    <span>Type:</span>
                    <b className="text-primary">
                      {this.props.pricing.features.type}
                    </b>
                  </p>
                </>
              ) : null}
            </div>
            <a
              href={this.props.pricing.link}
              className="btn btn-primary waves-effect waves-light mt-5"
              target="_blank"
              rel="noopener noreferrer"
            >
              Buy
            </a>
            {/* Modal for larger image display */}
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggleModal}
              size="lg"
            >
              <ModalHeader toggle={this.toggleModal}>
                {this.props.pricing.title}
              </ModalHeader>
              <ModalBody
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "300px" }}
              >
                <img
                  src={this.props.pricing.image}
                  alt={`${this.props.pricing.title} larger view`}
                  className="img-fluid"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "70vh", // Set max height to 90% of the viewport height
                    height: "auto", // Maintain aspect ratio
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggleModal}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </Col>
      </React.Fragment>
    );
  }
}

export default PricingBox;
