import React, { Component } from "react";
// import Section from "./section-download";
import Footer from "../../components/Footer/footer";
import { Container } from "reactstrap";
//Import Section Title
import SectionTitle from "../../components/common/section-title";

import NavbarPage from "../../components/Navbar/NavbarPage";

//Import Home Button
import AccountHomeButton from "../Auth/account-home-button";

class Index9 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navClass: "navbar-white",
    };
  }

  componentDidMount() {
    document.getElementById("colorTheme").href = "assets/colors/blue.css";
  }

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage navClass={this.state.navClass} />

        {/* render home button */}
        <AccountHomeButton />
        <section className="section bg-light home-half">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Job Openings"
              description="Currently, there are no job openings. Please check back later for future opportunities."
            />
          </Container>
        </section>

        {/* Importing Get Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index9;
