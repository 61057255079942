import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from "./pricing-box";

class Pricing extends Component {
  state = {
    pricings: [
      {
        id: 1,
        type: "inverter",
        image: "assets/images/products/psw150012c.png",
        title: "PSW-1500W-12V-C",
        price: "219.00",
        model: "Pure Sine Wave Inverter Charger",
        link: "",
        isNew: true,
        isPopular: false,
        specifications: {
          input: "12 VDC, 120 VAC",
          output: "120 VAC, 60Hz, 1500W",
          usb: "Quick Charge 3.0 x 2",
        },
        features: {
          inverterCharger: "Bi-directional",
          bypass: "Auto, < 16ms",
          charging: "3-Stage, 20A",
          smartphoneControl: "iOS/Android",
        },
      },
      {
        id: 2,
        type: "ats",
        image: "assets/images/products/ats16a-120.png",
        title: "ATS-16A-120VAC",
        price: "65.00",
        model: "Automatic Transfer Switch",
        link: "",
        isNew: false,
        isPopular: false,
        specifications: {
          inputMain: "100~130 VAC",
          inputSub: "0~130 VAC",
          output: "120 VAC, 60Hz",
          power: "16A / 1920W",
        },
        features: {
          housing: "ABS w/ IP67",
          fastSwitchingTime: "< 20ms",
          type: "Open Transition Transfer",
        },
      },
      {
        id: 3,
        type: "ats",
        image: "assets/images/products/ats16a-230.png",
        title: "ATS-16A-230V",
        price: "65.00",
        model: "Automatic Transfer Switch",
        link: "https://a.co/d/4wLTZ0E",
        specifications: {
          inputMain: "190~240 VAC",
          inputSub: "0~240 VAC",
          output: "230 VAC, 50Hz",
          power: "16A / 3680W",
        },
        features: {
          housing: "ABS w/ IP67",
          fastSwitchingTime: "< 20ms",
          type: "Open Transition Transfer",
        },
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="products">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Products"
              description="Our premier product lineup includes advanced pure sine wave inverter chargers with integrated smartphone control, as well as automatic AC transfer switches, designed to deliver seamless and reliable power management for a variety of applications."
            />

            <Row className="mt-5">
              {/* Render Pricing Box */}
              {this.state.pricings.map((pricing, key) => (
                <PricingBox key={key} pricing={pricing} />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Pricing;
