import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

//Import Particles
// import Particles from "react-particles-js";

//Importing Modal
// import ModalSection from "../../components/common/ModalSection";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    //particales
    const particlesInit = async (main) => {
      console.log(main);
      await loadFull(main);
    };

    const particlesLoaded = (container) => {
      console.log(container);
    };

    return (
      <React.Fragment>
        <section className="section bg-home vh-100 active" id="home">
          <div className="bg-overlay">
            <Particles
              id="tsparticles"
              init={particlesInit}
              loaded={particlesLoaded}
              options={{
                style: {
                  position: "absolute",
                },
                fpsLimit: 120,
                interactivity: {
                  events: {
                    onClick: {
                      enable: true,
                      mode: "push",
                    },
                    onHover: {
                      enable: true,
                      mode: "repulse",
                    },
                    resize: true,
                  },
                  modes: {
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 100,
                      duration: 9,
                    },
                  },
                },
                particles: {
                  color: {
                    value: "#ffffff",
                  },
                  links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: true,
                    opacity: 0.3,
                    width: 2,
                  },
                  collisions: {
                    enable: true,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                      default: "bounce",
                    },
                    random: false,
                    speed: 2,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 900,
                    },
                    value: 100,
                  },
                  opacity: {
                    value: 0.4,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    value: { min: 1, max: 5 },
                  },
                },
                detectRetina: true,
              }}
            />
          </div>

          <Container className="slidero z-3">
            <Row className="justify-content-center">
              <Col lg={{ size: 8 }} className="text-white text-center">
                <h4 className="home-small-title">Smartphone Control</h4>
                <h1 className="home-title">
                  Innovative way to connect
                  <br />
                  Power Inverter
                </h1>
                <p className="pt-3 home-desc mx-auto">
                  Inverlux uses Bluetooth to communicate with the compatible
                  inverters and other power related products. User can remotely
                  view the status and adjust settings of the product. Available
                  for both iOS & Android.
                </p>
                <p
                  className="play-shadow mt-4"
                  data-bs-toggle="modal"
                  data-bs-target="#watchvideomodal"
                >
                  {/* <Link
                        onClick={this.callModal}
                        to="#"
                        className="play-btn video-play-icon"
                      >
                        <i className="mdi mdi-play text-center"></i>
                      </Link> */}
                </p>
              </Col>
            </Row>
            {/* Row for iOS and Android app store images */}
            <Row className="justify-content-center mt-4">
              <Col xs="auto" className="text-center">
                <a
                  href="https://apps.apple.com/us/app/linkup-power-management/id1532700010#?platform=iphone"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/download-app-store.png"
                    alt="Download on the App Store"
                    style={{ maxWidth: "200px" }}
                  />
                </a>
              </Col>
              <Col xs="auto" className="text-center">
                <a
                  href="https://play.google.com/store/apps/details?id=com.linkchamp.linkup"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/images/download-google-play.png"
                    alt="Get it on Google Play"
                    style={{ maxWidth: "210px" }}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
