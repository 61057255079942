import React, { Component } from "react";
import Section from "./section-download";
import Footer from "../../components/Footer/footer";

import NavbarPage from "../../components/Navbar/NavbarPage";

//Import Home Button
import AccountHomeButton from "../Auth/account-home-button";

class Index9 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navClass: "navbar-white",
    };
  }

  componentDidMount() {
    document.getElementById("colorTheme").href = "assets/colors/blue.css";
  }

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage navClass={this.state.navClass} />

        {/* render home button */}
        <AccountHomeButton />

        {/* Importing Section */}
        <Section />

        {/* Importing Get Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index9;
