import Index1 from "./pages/Index1/Index1";
import JOB from "./pages/Index1/jobs";
import FAQ from "./pages/Index1/faq";
import Download from "./pages/Index1/download";
import Privacy from "./pages/Index1/privacy";
import Terms from "./pages/Index1/terms";

const routes = [
  { path: "/", component: <Index1 /> },
  { path: "/jobs", component: <JOB /> },
  { path: "/faq", component: <FAQ /> },
  { path: "/download", component: <Download /> },
  { path: "/privacy", component: <Privacy /> },
  { path: "/terms", component: <Terms /> },
];

export default routes;
